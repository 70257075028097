import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useActions, useReduxState } from "re-reduced";

import { TextInput, Button, Modal } from "@components/shared/sub";
import {
  Container,
  GiveMeSomeSpace,
  GridContainer,
  H2,
  H3,
  P,
} from "@util/standard";
import { isNamesQuery, searchOrganizations } from "@util/api";
import { OrgIdentity } from "@util/interfaces";
import ScanToLogin from "./scanToLogin";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { assets, colors, loadingText, noResultText } from "@util/constants";
import { RequestStatus } from "@state/types";
import { useDebounce } from 'usehooks-ts';

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 80%;
  margin: auto;
  height: 100%;
  justify-content: space-around;
`;

const SearchOrgContainer = styled(Container)`
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding-top: 30px;
`;

const RelativeContainer = styled(Container)`
  position: relative;
`;

const SearchResultContainer = styled(RelativeContainer)`
  padding: 15px 10px;
  background-color: ${colors.grey};
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Icon = styled.img`
  position: absolute;
  right: 20px;
  top: 18px;
`;

const LoginWithQrContainer = styled(Container)`
  position: fixed;
  justify-content: center;
  background-color: black;
  padding: 10px;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface State {
  email: string;
  password: string;
}



const Login = () => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const debouncedSearchTerm = useDebounce<string>(searchTerm ?? "", 300);
  const [selectedOrg, setSelectedOrg] = useState<
    OrgIdentity
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    OrgIdentity[]
  >();
  const [isValidating, setIsValidating] = useState(false);
  const [scanLogin, setScanLogin] = useState(false);
  const [state, setState] = useState<State>({
    email: "",
    password: "",
  });

  const { login, updateRoute } = useActions(actions);
  const { route, requestStatus } = useReduxState({
    route: selectors.getRoute,
    requestStatus: selectors.getRequestStatus,
  });

  const searchOrgs = async (search?: string) => {
    if (search && search.length > 2) {
      setIsLoading(true);
      const orgResults = await searchOrganizations(search);
      if (isNamesQuery(orgResults)) {
        const nodes = orgResults.organizationNames.edges.map(
          (edge) => edge.node
        );
        setSearchResults(nodes);
      }
      setIsLoading(false);
    } else {
      setSearchResults(undefined);
    }
  };

  useEffect(() => {
    searchOrgs(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleOrgInputChange = (term: string) => {
    setSearchTerm(term);
  };

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleLoginClick = () => {
    setIsValidating(true);

    if (selectedOrg && selectedOrg.id) {
      const { email, password } = state;
      const payload = { email, password, orgId: selectedOrg.id };
      login(payload);
    }
  };

  const SearchResult = ({
    org,
  }: {
    org: OrgIdentity;
  }) => {
    return (
      <SearchResultContainer
        onClick={() => {
          setSelectedOrg(org);
          updateRoute("login");
        }}
      >
        <P margin="0">{org.name}</P>
        <Icon src={assets.rightArrow} />
      </SearchResultContainer>
    );
  };

  if (route === "search-org") {
    return (
      <SearchOrgContainer>
        <RelativeContainer>
          <TextInput
            placeholderText="Organisation name"
            onChange={(event: any) => handleOrgInputChange(event.target.value)}
            margin="0 0 20px 0"
            autoFocus={true}
          />
          <Icon src={assets.search} />
        </RelativeContainer>
        {searchResults && searchResults.length > 0 ? (
          <Container flexDirection="column">
            {searchResults?.map((org) => (
              <SearchResult key={org.id} org={org} />
            ))}
          </Container>
        ) : (
          <div>
            {debouncedSearchTerm !== "" && (
              <H3>{isLoading ? loadingText : noResultText}</H3>
            )}
          </div>
        )}
      </SearchOrgContainer>
    );
  }

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleLoginClick();
    }
  };

  return (
    <Wrapper>
      <Container flexDirection="column" alignItems="center">
        <img width="100%" height="auto" src={assets.logo} />
        <H2 margin="10px" >
          SCANNER
        </H2>
      </Container>
      <form id="login-form" onSubmit={(e: FormEvent) => e.preventDefault()}>
        <GridContainer width="100%" repeat={1} tabletRepeat={1} rowGap={20}>
          <Button
            type="button"
            theme="inverse"
            text={selectedOrg ? selectedOrg.name : "Select Organisation"}
            borderColor={isValidating && !selectedOrg ? "red" : "white"}
            onClick={() => updateRoute("search-org")}
          />
          <TextInput
            isTransparent
            name="email"
            type="email"
            placeholderText="Email"
            onChange={handleInputChange}
            value={state.email}
            color={isValidating && state.email === "" ? "red" : "white"}
          />
          <TextInput
            isTransparent
            name="password"
            type="password"
            placeholderText="Password"
            value={state.password}
            onChange={handleInputChange}
            onKeyDown={(e) => handleKeyPress(e)}
            color={isValidating && state.password === "" ? "red" : "white"}
          />
          <GiveMeSomeSpace space={1} />
          <Button
            theme="base"
            text="LOG IN"
            onClick={handleLoginClick}
            width="100%"
            loading={requestStatus === RequestStatus.Pending}
          />
        </GridContainer>
      </form>
      <GiveMeSomeSpace />
      <LoginWithQrContainer onClick={() => setScanLogin(true)}>
        <P>LOGIN WITH QR CODE</P>
      </LoginWithQrContainer>

      <Modal visible={scanLogin} onClose={() => setScanLogin(false)}>
        <ScanToLogin />
      </Modal>
    </Wrapper >
  );
};

export default Login;
